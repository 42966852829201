<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('notification.categoriesList')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="categoryHeaders"
            :items="categories"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in categoryHeaders"
                :key="header.value + index"
              >
                <div v-if="header.value == 'actions'">
                  <!-- Create voucher -->
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="primary"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="viewNotificationContentByCategory(item['id'], item['name'])"
                      >
                        <v-icon>mdi-view-list-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('templateEmail.category.viewDetail') }}</span>
                  </v-tooltip>
                </div>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="paginate.totalPage"
      :current-page="paginate.currentPage"
      :row-per-page="paginate.rowPerPage"
      @onPageChange="onPageChange"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    categoryHeaders: [
      { sortable: false, text: 'common.nonumber', value: 'stt' },
      { sortable: false, text: 'templateEmail.category.table.headerName', value: 'name' },
      { sortable: false, text: 'templateEmail.category.table.headerNumberOfTemplate', value: 'numberOfTemplate' },
      { sortable: false, text: 'common.actions', value: 'actions' }
    ],
    categories: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    }
  }),
  computed: {
    ...mapGetters([
      'NOTIFICATION_CATEGORIES_DATA'
    ])
  },
  watch: {
    NOTIFICATION_CATEGORIES_DATA () {
      let res = this.NOTIFICATION_CATEGORIES_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let notificationCategories = res.results
      this.categories = []
      for (let i = 0, size = notificationCategories.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: notificationCategories[i].id,
          name: notificationCategories[i].name,
          numberOfTemplate: notificationCategories[i].numberOfNotification
        }
        this.categories.push(obj)
      }
    }
  },
  created () {
    this.getNotificationCategories()
  },
  methods: {
    /**
     * View template email by category
     */
    viewNotificationContentByCategory: function (id, name) {
      this.$router.push({
        'path': `/notification-content-list/${id}`,
        params: {
          id: id
        },
        query: {
          name: name
        }
      })
    },
    /**
     * Get template email categories
     */
    getNotificationCategories: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage
        }
      }
      this.GET_NOTIFICATION_CATEGORIES(filter)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getNotificationCategories()
    },
    ...mapActions([
      'GET_NOTIFICATION_CATEGORIES'
    ])
  }
}
</script>

<style></style>
